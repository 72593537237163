import firebase, { database } from "firebase";
import "firebase/storage";
import { UserDatabaseConnector } from "../dataconnector/usersDatabaseConnector";
import { STAGE } from "../stage";

const firebaseConfigDev = {
  apiKey: "AIzaSyDsqPEHPLTINaJMYlcm_KCoBWdaIOdWbd4",
  authDomain: "aws-dev-606fa.firebaseapp.com",
  projectId: "aws-dev-606fa",
  storageBucket: "aws-dev-606fa.appspot.com",
  messagingSenderId: "86243752716",
  appId: "1:86243752716:web:4285b1543127e53c3b6729",
  measurementId: "G-53RFND8GT4",
};

const firebaseConfigStage = {
  apiKey: "AIzaSyCJjVnBzsxSxf2XbWsEpHkTQBnK9WAp3gM",
  authDomain: "aws-stage.firebaseapp.com",
  projectId: "aws-stage",
  storageBucket: "aws-stage.appspot.com",
  messagingSenderId: "857023048638",
  appId: "1:857023048638:web:b8921970b48d128c2c27ef",
  measurementId: "G-F7TP08Q8Y0",
};

const firebaseConfigQA = {
  apiKey: "AIzaSyDOe5syGMDpPiPXW0kZgCkjoNHU8iDhfS0",
  authDomain: "aws-qa-3fcb7.firebaseapp.com",
  projectId: "aws-qa-3fcb7",
  storageBucket: "aws-qa-3fcb7.appspot.com",
  messagingSenderId: "392313088238",
  appId: "1:392313088238:web:f035d85d9a8a4bcc4a6bff",
  measurementId: "G-2LPMSQXVB2",
  databaseURL:
    "https://aws-qa-3fcb7-default-rtdb.europe-west1.firebasedatabase.app",
};

const firebaseConfigProd = {
  apiKey: "AIzaSyA8oURerdW0TEvp_2ubjEg3TABlD2r0ZR4",
  authDomain: "aws-prod-3fac0.firebaseapp.com",
  projectId: "aws-prod-3fac0",
  storageBucket: "aws-prod-3fac0.appspot.com",
  messagingSenderId: "185601465462",
  appId: "1:185601465462:web:3a048766647f493dc3d357",
  measurementId: "G-33P7D6BQ5X",
  databaseURL:
    "https://aws-prod-3fac0-default-rtdb.europe-west1.firebasedatabase.app",
};

export function initDb(stage) {
  if (stage === undefined) {
    stage = STAGE;
  }
  if (!firebase.apps.length) {
    switch (stage) {
      case "dev":
        firebase.initializeApp(firebaseConfigDev);
        break;
      case "stage":
        firebase.initializeApp(firebaseConfigStage);
        break;
      case "QA":
        firebase.initializeApp(firebaseConfigQA);
        break;
      case "prod":
        firebase.initializeApp(firebaseConfigProd);
        break;
      default:
        firebase.initializeApp(firebaseConfigDev);
        break;
    }
  }
  var database = firebase.firestore();
  return database;
}

export function initRealTimeDb() {
  if (!firebase.apps.length) {
    switch (STAGE) {
      case "dev":
        firebase.initializeApp(firebaseConfigDev);
        break;
      case "stage":
        firebase.initializeApp(firebaseConfigStage);
        break;
      case "QA":
        firebase.initializeApp(firebaseConfigQA);
        break;
      case "prod":
        firebase.initializeApp(firebaseConfigProd);
        break;
      default:
        firebase.initializeApp(firebaseConfigDev);
        break;
    }
  }
  var database = firebase.database();

  return database;
}

export function initAuth() {
  if (!firebase.apps.length) {
    switch (STAGE) {
      case "dev":
        firebase.initializeApp(firebaseConfigDev);
        break;
      case "stage":
        firebase.initializeApp(firebaseConfigStage);
        break;
      case "QA":
        firebase.initializeApp(firebaseConfigQA);
        break;
      case "prod":
        firebase.initializeApp(firebaseConfigProd);
        break;
      default:
        firebase.initializeApp(firebaseConfigDev);
        break;
    }
  }
  var auth = firebase.auth();
  return auth;
}

export function initFunctions() {
  let fireApp;
  if (!firebase.apps.length) {
    switch (STAGE) {
      case "dev":
        fireApp = firebase.initializeApp(firebaseConfigDev);
        break;
      case "stage":
        fireApp = firebase.initializeApp(firebaseConfigStage);
        break;
      case "QA":
        fireApp = firebase.initializeApp(firebaseConfigQA);
        break;
      case "prod":
        fireApp = firebase.initializeApp(firebaseConfigProd);
        break;
      default:
        fireApp = firebase.initializeApp(firebaseConfigDev);
        break;
    }
  } else {
    fireApp = firebase.apps[0];
  }
  var functions = fireApp.functions("europe-west3");
  return functions;
}

export function initStorage(bucket) {
  if (!firebase.apps.length) {
    switch (STAGE) {
      case "dev":
        firebase.initializeApp(firebaseConfigDev);
        break;
      case "stage":
        firebase.initializeApp(firebaseConfigStage);
        break;
      case "QA":
        firebase.initializeApp(firebaseConfigQA);
        break;
      case "prod":
        firebase.initializeApp(firebaseConfigProd);
        break;
      default:
        firebase.initializeApp(firebaseConfigDev);
        break;
    }
  }

  if (bucket !== null) {
    return firebase.app().storage(bucket);
  } else {
    return firebase.storage();
  }
}

export const DATACAP = {
  maxVideoSize: 50000000,
  maxSoundSize: 5000000,
  maxPhotoSize: 2000000,
};

export async function getPostMarkToken() {
  return "32854993-2b3f-4c3b-a836-e13e0534fd2c";
}

export async function getZooId() {
  const uConnector = new UserDatabaseConnector();
  let userData = await uConnector._getUserData();
  return userData.data.userZoo.value;
}

export const backgroundImage =
  "https://github.com/akongo-welfare/static/raw/main/akongo_website/background2023.jpg";

export function getZooList() {
  if (STAGE === "prod") {
    return zooListProd;
  } else {
    return zooList;
  }
}

export async function getActiveZooList(userId) {
  let db = initDb();
  let snap = await db
    .collection("zoo")
    .where("staffAccess", "array-contains", userId)
    .get();
  let list = [];
  snap.forEach((el) => {
    let data = el.data();
    list.push({
      label: data.zooName,
      value: data.zooId,
    });
  });

  return list;
}

export function getBucket(bucketName) {
  if (STAGE === "prod") {
    if (bucketName === "cdn") {
      return "gs://cdn_prod_welfare_manager";
    } else {
      return null;
    }
  } else if (STAGE === "QA") {
    if (bucketName === "cdn") {
      return "gs://cdn_qa_welfare_manager";
    } else {
      return null;
    }
  } else {
    if (bucketName === "cdn") {
      return "gs://cdn_dev_welfare_manager";
    } else {
      return null;
    }
  }
}

export const eulaVersion = 4;

export const zooList = [
  { label: "TEST AMELIE", value: "testAmelie" },
  { label: "TEST GWEN", value: "testGwen" },
  { label: "TEST LEA", value: "testLea" },
  { label: "TEST JOSEPH", value: "testJoseph" },
  { label: "Demo", value: "demo" },
];

export const zooListProd = [
  { label: "Parc animalier et botanique - Mulhouse", value: "MulhouseZoo" },
  { label: "Domaine de branféré", value: "branfere" },
  { label: "Zoo de la Palmyre", value: "palmyre" },
  { label: "Zoo de besancon", value: "besancon" },
  { label: "Puy du fou", value: "PuyDuFou" },
  { label: "Parc de cleres", value: "cleres" },
  { label: "Zoo Sanary", value: "zooSanary" },
  { label: "Parc animalier d'Auvergne", value: "auvergne" },
  { label: "ICM", value: "ICM" },
  { label: "Poitiers", value: "poitiers" },
  { label: "Akongo", value: "akongo" },
  { label: "Beekse Bergen", value: "BeekseBergen", nemo: "HILVARENB" },
  { label: "Antwerp", value: "antwerp", nemo: "ANTWERP" },
  { label: "Planckendael", value: "planckendael", nemo: "PLANCKNDL" },
  { label: "Formation AFSA", value: "afsatraining", nemo: "" },
];

export const ZOO_LIST = [
  {
    label: "Parc animalier et botanique - Mulhouse",
    value: "MulhouseZoo",
    nemo: "MULHOUSE",
  },
  { label: "Zoo de la Palmyre", value: "palmyre", nemo: "LA PALMYR" },
  { label: "Parc de branfére", value: "branfere", nemo: "GUERNO" },
  { label: "Parc de cleres", value: "cleres", nemo: "CLERES" },
  { label: "Puy du fou", value: "PuyDuFou", nemo: "LES EPESS" },
  { label: "Zoo de besancon", value: "besancon", nemo: "BESANCON" },
  { label: "Beekse Bergen", value: "BeekseBergen", nemo: "HILVARENB" },
  { label: "Antwerp", value: "antwerp", nemo: "ANTWERP" },
  { label: "Planckendael", value: "planckendael", nemo: "PLANCKNDL" },
  { label: "Eindhoven Zoo", value: "eindhoven", nemo: "MIERLO" },
  { label: "Formation AFSA", value: "afsatraining", nemo: "" },
];

export const LANGUAGE_AVAILABLE = ["fr", "en", "nl", "de", "es", "it"];

export const ACTIVE_LANGUAGE = [
  { label: "FR", value: "fr", flagUrl: null },
  { label: "EN", value: "en", flagUrl: null },
  { label: "NL", value: "nl", flagUrl: null },
  { label: "DE", value: "de", flagUrl: null },
  { label: "ES", value: "es", flagUrl: null },
  { label: "IT", value: "it", flagUrl: null },
];
